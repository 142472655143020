import React, { FC } from 'react';
import type { RichContent } from 'ricos-schema';
import { useEnvironment, useExperiments } from '@wix/yoshi-flow-editor';
import { WixRicosViewer, WixRicosViewerNext } from '@wix/ricos-viewer';

import {
  useInstance,
  useDefaultBIParams,
  useMember,
} from '../../../../../../contexts/widgets';
import { biSettings, plugins } from './config';
import '../../../common/RCE/ricos.global.scss';
import { Experiment } from '../../../../../../types';

export const Viewer: FC = () => {
  const { isMobile, language } = useEnvironment();
  const { instance } = useInstance();
  const { defaultBIParams } = useDefaultBIParams();
  const { experiments } = useExperiments();
  const { id, about } = useMember();

  if (experiments.enabled(Experiment.UseMembersAboutV2)) {
    return (
      <WixRicosViewerNext
        theme={{
          palette: {
            type: 'rgb',
            textColor: 'var(--section-description-color)',
          },
          typography: {
            fontFamily: 'var(--section-description-font-family)',
          },
          customStyles: {
            p: {
              fontSize: isMobile
                ? 'calc(var(--sectionDescriptionFontSize) * 1px)'
                : 'var(--section-description-font-size)',
            },
          },
        }}
        isMobile={isMobile}
        plugins={plugins}
        instance={instance}
        wixExperiments={experiments}
        biSettings={{
          ...biSettings,
          contentId: id ?? undefined,
          defaultParams: defaultBIParams,
        }}
        content={about as unknown as RichContent}
        locale={language}
        linkSettings={{ rel: { nofollow: true } }}
      />
    );
  }

  return (
    <WixRicosViewer
      theme={{
        palette: {
          type: 'rgb',
          textColor: 'var(--section-description-color)',
        },
        typography: {
          fontFamily: 'var(--section-description-font-family)',
        },
        customStyles: {
          p: {
            fontSize: isMobile
              ? 'calc(var(--sectionDescriptionFontSize) * 1px)'
              : 'var(--section-description-font-size)',
          },
        },
      }}
      content={about}
      plugins={plugins}
      instance={instance}
      wixExperiments={experiments}
      isMobile={isMobile}
      locale={language}
      biSettings={{
        ...biSettings,
        contentId: id ?? undefined,
        defaultParams: defaultBIParams,
      }}
      linkSettings={{ rel: { nofollow: true } }}
    />
  );
};
